<template>
  <div class="lineRelation">
    <div class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="right"
        label-width="98px"
      >
        <el-form-item label="定制客运线路：">
          <mainLineSelectForSearch
            @getSelectVal="getLineSelectVal"
            placeholder="请选择定制客运线路"
          ></mainLineSelectForSearch>
        </el-form-item>
        <el-form-item label="汽车票起点站：">
          <siteNameSelectForSearch
            placeholder="请选择汽车票起点站"
            @getSelectVal="getStartSiteSelectVal"
          ></siteNameSelectForSearch>
        </el-form-item>
        <el-form-item label="汽车票终点站：">
          <siteNameSelectForSearch
            placeholder="请选择汽车票终点站"
            @getSelectVal="getEndSiteSelectVal"
          ></siteNameSelectForSearch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
          <el-button
            v-if="listFind('新建关联')"
            type="primary"
            size="small"
            @click="addFun"
            >新建关联</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      ref="table"
      :operation="true"
      :table-data="tableData"
      :title-name="titleName"
      operation-width="150"
    >
      <template slot-scope="{ scopeRow }"
        ><el-button
          v-if="listFind('编辑')"
          type="text"
          size="small"
          sort="primary"
          @click="editFun(scopeRow)"
          >编辑</el-button
        >
        <el-button
          v-if="listFind('删除')"
          type="text"
          size="small"
          sort="primary"
          @click="deleteFun(scopeRow.id)"
          >删除</el-button
        ></template
      >
    </Table>
    <div class="pagination">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      v-if="show"
      :visible.sync="show"
      :title="title"
      width="1000px"
      :close-on-click-modal="false"
      @close="closeFun"
    >
      <div class="ruleForm">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          label-position="right"
          label-width="140px"
        >
          <el-form-item
            label="定制客运线路："
            prop="customLineCode"
            :rules="{
              required: true,
              message: '请选择定制客运线路',
              trigger: 'blur',
            }"
          >
            <mainLineSelectForSearch
              ref="lineSelectRef"
              @getSelectVal="getLineSelectValDia"
              placeholder="请选择定制客运线路"
              :disabled="title == '编辑关联'"
              :defaultValue="ruleForm.customLineCode"
            ></mainLineSelectForSearch>
          </el-form-item>
          <el-form-item
            label="关联汽车票起点站："
            prop="startSiteName"
            :rules="{
              required: true,
              message: '请选择关联汽车票起点站',
              trigger: 'change',
            }"
            :style="{
              marginBottom:
                ruleForm.startSiteName.length && !showStart ? 0 : '22px',
            }"
          >
            <div>
              <el-tag
                v-for="(tag, index) in ruleForm.startSiteName"
                :key="index"
                :closable="showStart"
                style="margin-right: 10px"
                @close="closeStart(tag, index)"
              >
                {{ tag }}
              </el-tag>
            </div>
            <el-button v-if="!showStart" type="text" @click="selectStart"
              >请选择</el-button
            >
          </el-form-item>
          <div v-if="showStart" class="lines">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                v-for="tab in tabs"
                :key="tab"
                :label="tab"
                :name="tab"
              >
                <div class="panes">
                  <div
                    v-for="(item, index) in panes"
                    :key="index"
                    :class="['item', item.checked ? 'checked' : '']"
                    @click="clickSite(item, index)"
                  >
                    {{ item.siteName }}
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
          <el-form-item
            label="关联汽车票终点站："
            prop="endSiteName"
            :rules="{
              required: true,
              message: '请选择关联汽车票终点站',
              trigger: 'change',
            }"
            :style="{
              marginBottom:
                ruleForm.endSiteName.length && !showEnd ? 0 : '22px',
            }"
          >
            <div>
              <el-tag
                v-for="(tag, index) in ruleForm.endSiteName"
                :key="index"
                :closable="showEnd"
                style="margin-right: 10px"
                @close="closeEnd(tag, index)"
              >
                {{ tag }}
              </el-tag>
            </div>
            <el-button v-if="!showEnd" type="text" @click="selectEnd"
              >请选择</el-button
            >
          </el-form-item>
          <div v-if="showEnd" class="lines">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane
                v-for="tab in tabs"
                :key="tab"
                :label="tab"
                :name="tab"
              >
                <div class="panes">
                  <div
                    v-for="(item, index) in panes"
                    :key="index"
                    :class="['item', item.checked ? 'checked' : '']"
                    @click="clickSite(item, index)"
                  >
                    {{ item.siteName }}
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeFun">取 消</el-button>
        <el-button type="primary" :disabled="loading" @click="confirmFun"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryIntercityMainLinePageAPI,
  siteListAPI,
  saveCustomLineIntercityAssociation,
  updateCustomLineIntercityAssociation,
  queryCustomLineIntercityAssociationPage,
  deleteCustomLineIntercityAssociationById,
} from "@/api/lib/api";
import mainLineSelectForSearch from '@/components/treeSelect/mainLineSelectForSearch.vue';
import siteNameSelectForSearch from '../../../components/treeSelect/siteNameSelectForSearch.vue';
export default {
  components: {
    mainLineSelectForSearch,
    siteNameSelectForSearch,
  },
  data () {
    return {
      form: {
        currentPage: 1,
        pageSize: 10,
        customLineCode: null,
        startSiteName: "",
        endSiteName: "",
      },
      total: 0,
      siteList: [],
      lineList: [],
      tableData: [],
      titleName: [
        {
          title: "定制客运线路",
          props: "customLineCode",
          SpecialJudgment: (res) =>
            this.lineList?.find((e) => e.lineCode == res)?.lineName,
        },
        {
          title: "关联汽车票起点站",
          props: "startSiteName",
          SpecialJudgment: (res) => res.join("、"),
        },
        {
          title: "关联汽车票终点站",
          props: "endSiteName",
          SpecialJudgment: (res) => res.join("、"),
        },
      ],
      show: false,
      activeName: "全部",
      tabs: [
        "全部",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "k",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      panes: [],
      title: "",
      ruleForm: {
        customLineCode: "",
        startSiteName: [],
        endSiteName: [],
        endSiteCode: [],
        startSiteCode: [],
      },
      showStart: false,
      showEnd: false,
      loading: false,
    };
  },
  created () {
    this.getMainLineList();
  },
  mounted () {
    siteListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.siteList = res.data;
        this.panes = res.data;
      }
    });
    this.queryFun();
  },
  methods: {
    getLineSelectValDia (val) {
      this.ruleForm.customLineCode = val ? val : null;
    },
    getLineSelectVal (val) {
      this.form.customLineCode = val ? val : null;//非空时必须传null才会查询出所有
    },
    getStartSiteSelectVal (val) {
      this.form.startSiteName = val;
    },
    getEndSiteSelectVal (val) {
      this.form.endSiteName = val;
    },
    getMainLineList () {
      queryIntercityMainLinePageAPI({ currentPage: 1, pageSize: 10000 }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.lineList = res.data.list;
          }
        }
      );
    },
    queryFun () {
      this.form.currentPage = 1;
      this.renderData();
    },
    renderData () {
      queryCustomLineIntercityAssociationPage(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    addFun () {
      queryCustomLineIntercityAssociationPage({
        currentPage: 1,
        pageSize: 10000,
      }).then((res) => {
        this.show = true;
        this.title = "新建关联";
        if (res.code == "SUCCESS") {
          this.lineList.forEach((e) => {
            if (res.data.list.some((i) => i.customLineCode == e.lineCode)) {
              e.disabled = true;
            } else {
              e.disabled = false;
            }
          });
        }
      });
    },
    editFun (row) {
      this.show = true;
      this.title = "编辑关联";
      this.ruleForm = this.deepClone(row);
      this.$nextTick(()=>{
        this.$refs.lineSelectRef.initHandle();
      })
    },
    deleteFun (id) {
      this.$confirm("确定删除此线路关联吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteCustomLineIntercityAssociationById(id).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.queryFun();
          }
        });
      });
    },
    // 分页页数发生变化
    onCurrentChange (val) {
      this.form.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange (val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.renderData();
    },
    selectStart () {
      this.showStart = true;
      this.activeName = "全部";
      this.showEnd = false;
      this.$refs.ruleForm.clearValidate("startSiteName");
      let arr = this.deepClone(this.siteList);
      arr.forEach((e) => {
        if (this.ruleForm.startSiteName.some((i) => i == e.siteName)) {
          e.checked = true;
        } else {
          e.checked = false;
        }
      });
      this.panes = arr;
    },
    selectEnd () {
      this.showStart = false;
      this.activeName = "全部";
      this.showEnd = true;
      this.$refs.ruleForm.clearValidate("endSiteName");
      let arr = this.deepClone(this.siteList);
      arr.forEach((e) => {
        if (this.ruleForm.endSiteName.some((i) => i == e.siteName)) {
          e.checked = true;
        } else {
          e.checked = false;
        }
      });
      this.panes = arr;
    },
    handleClick () {
      let arr = [];
      if (this.activeName == "全部") arr = this.deepClone(this.siteList);
      else arr = this.siteList.filter((e) => e.siteCode[0] == this.activeName);
      if (this.showStart) {
        arr.forEach((e) => {
          if (this.ruleForm.startSiteName.some((i) => i == e.siteName)) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        });
      } else {
        arr.forEach((e) => {
          if (this.ruleForm.endSiteName.some((i) => i == e.siteName)) {
            e.checked = true;
          } else {
            e.checked = false;
          }
        });
      }
      this.panes = arr;
    },
    clickSite (item, index) {
      if (this.showStart) {
        if (this.ruleForm.startSiteName.some((e) => e == item.siteName)) {
          this.panes[index].checked = false;
          const seq = this.ruleForm.startSiteName.findIndex(
            (e) => item.siteName == e
          );
          this.ruleForm.startSiteName.splice(seq, 1);
          this.ruleForm.startSiteCode.splice(seq, 1);
        } else {
          this.ruleForm.startSiteName.push(item.siteName);
          this.ruleForm.startSiteCode.push(item.siteCode);
          this.panes[index].checked = true;
        }
      } else {
        if (this.ruleForm.endSiteName.some((e) => e == item.siteName)) {
          this.panes[index].checked = false;
          const seq = this.ruleForm.endSiteName.findIndex(
            (e) => item.siteName == e
          );
          this.ruleForm.endSiteName.splice(seq, 1);
          this.ruleForm.endSiteCode.splice(seq, 1);
        } else {
          this.ruleForm.endSiteName.push(item.siteName);
          this.ruleForm.endSiteCode.push(item.siteCode);
          this.panes[index].checked = true;
        }
      }
    },
    closeStart (tag, index) {
      this.ruleForm.startSiteName.splice(index, 1);
      this.ruleForm.startSiteCode.splice(index, 1);
      const seq = this.panes.findIndex((item) => item.siteName == tag);
      this.panes[seq].checked = false;
    },
    closeEnd (tag, index) {
      this.ruleForm.endSiteName.splice(index, 1);
      this.ruleForm.endSiteCode.splice(index, 1);
      const seq = this.panes.findIndex((item) => item.siteName == tag);
      this.panes[seq].checked = false;
    },
    closeFun () {
      this.show = false;
      this.showStart = false;
      this.showEnd = false;
      this.loading = false;
      this.lineList.forEach((e) => (e.disabled = false));
      this.ruleForm = {
        customLineCode: "",
        startSiteName: [],
        endSiteName: [],
        endSiteCode: [],
        startSiteCode: [],
      };
    },
    confirmFun () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.title == "新建关联") {
            saveCustomLineIntercityAssociation(this.ruleForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("新建关联成功");
                this.closeFun();
                this.queryFun();
              }
            });
          } else {
            updateCustomLineIntercityAssociation(this.ruleForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("编辑关联成功");
                this.closeFun();
                this.queryFun();
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.lineRelation {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  .Table {
    margin-top: 16px;
  }
  .ruleForm {
    padding: 30px;
    box-sizing: border-box;
    .lines {
      border: 1px solid #e9e9e9;
      border-radius: 3px;
      padding: 10px;
      box-sizing: border-box;
      margin-top: 10px;
      .panes {
        height: 200px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        .item {
          margin: 10px;
          margin-top: 0;
          width: 86px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          cursor: pointer;
          &:hover {
            color: #336ffe;
          }
        }
        .checked {
          color: #fff;
          background-color: #336ffe;
          border-radius: 4px;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
