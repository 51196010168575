<template>
  <!-- 站点模糊搜索 -->  
  <el-select
    v-model="siteName"
    clearable
    filterable
    :placeholder="placeholder"  
    @change="handleChange"
  >
    <el-option
      v-for="role in siteList"
      :key="role.siteID"
      :label="role.siteName"
      :value="role.siteName"
    ></el-option>
  </el-select>
</template>
<script>
import {
  siteListAPI,
} from "@/api/lib/api";
export default {
  props:{
    placeholder: {
    type: String,
    default: "请选择"
  },
  },
  data () {
    return {
      siteName: null,
      siteList: [],
    }
  },
  created () {

  },
  mounted () {
    this.getSiteList();
  },
  methods: {  
    handleClear(){
      this.siteList = this.copySiteList;
    },
    getSiteList () {
      siteListAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.siteList = res.data;
        }
      });
    },
    handleChange(val){
      this.$emit('getSelectVal',val)
    }
  },
}
</script>