<template>
  <!-- 主线路模糊搜索 -->  
  <el-select
    v-model="customLineCode"
    clearable
    filterable    
    :placeholder="placeholder"
    :disabled="disabled"    
    @clear="customLineCode = null"
    @change="handleChange"
  >
    <el-option
      v-for="item in lineList"
      :key="item.lineCode"
      :label="item.lineName"
      :value="item.lineCode"
    ></el-option>
  </el-select>
</template>
<script>
import {
  queryIntercityMainLinePageAPI,  
} from "@/api/lib/api";
export default {
  props:{
    placeholder:{
      type:String,
      default:"请选择"
    },
    disabled:{
      type:Boolean,
      default:false
    },
    defaultValue:{
      type:String,
      default:null
    }
  },
  data () {
    return {
      lineList: [],
      customLineCode: null,
    }
  },
  created () {
    this.getMainLineList();
  },
  mounted () {    
  },
  methods: {
    initHandle(){
      this.customLineCode = this.defaultValue;
    },
    getMainLineList () {
      queryIntercityMainLinePageAPI({ currentPage: 1, pageSize: 10000 }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.lineList = res.data.list;
          }
        }
      );
    },
    handleChange(val){
      this.$emit('getSelectVal',val)
    }
  },
}
</script>