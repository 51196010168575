var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: { clearable: "", filterable: "", placeholder: _vm.placeholder },
      on: { change: _vm.handleChange },
      model: {
        value: _vm.siteName,
        callback: function ($$v) {
          _vm.siteName = $$v
        },
        expression: "siteName",
      },
    },
    _vm._l(_vm.siteList, function (role) {
      return _c("el-option", {
        key: role.siteID,
        attrs: { label: role.siteName, value: role.siteName },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }